@media print {
    .footer-top-wrapper .left,
    .footer-bottom-inner .left,
    .column.main {
        padding: 0;
    }

    .product.info.detailed {
        margin-bottom: 0;
    }

    .footer-top-wrapper {
        margin-top: 0;
    }

    .product.attribute .read-more {
        height: auto;
        -webkit-text-fill-color: unset;
    }

    .media .fotorama__stage__frame .fotorama__img {
        display: block;
        left: unset;
        margin: 0 auto;
        position: relative;
        top: unset;
        transform: unset;
    }

    // Shame below! (mostly inline overrides, one or two to reduce required selectors)
    .amlabel-position-wrapper,
    .amasty-label-text,
    .amasty-label-container {
        display: none !important;
        height: 0;
    }

    .breadcrumbs,
    .footer-bottom-inner .right,
    .footer-top-wrapper .middle,
    .footer-top-wrapper .right,
    .fotorama__img--full,
    .fotorama__nav-wrap,
    .leaflet,
    .product-add-form,
    .product-enquire-form,
    .product-info-manufacturer,
    .product-reviews-summary,
    .product-social-links,
    .product-widget-block,
    .product.lower .right,
    .read-more-link,
    .usp-container,
    #tab-label-reviews-title,
    #reviews {
        display: none !important;
    }

    .fotorama-item.fotorama {
        &,
        *:not(.fotorama__nav-wrap) {
            display: block;
            height: auto !important;
            overflow: visible;
            position: relative;
            width: auto !important;
        }
    }

    .logo {
        background-color: black !important;
        -webkit-print-color-adjust: exact;
    }

    .product-info-price {
        border: none !important;
        padding: 10px 0 !important;

        .price-box {
            text-align: left !important;
        }
    }

    .product.info.detailed .data {
        .data.item.content {
            border: none;
            display: block !important;
            padding: 5px;
        }

        .data.item.title .data.switch {
            border: none;
            height: auto;
            line-height: 20px;
            padding: 5px;
        }
    }
}
